






















































































import { defineComponent, PropType, computed } from '@nuxtjs/composition-api';
import { RawLocation } from 'vue-router';
import AppIcon from '~/components/partials/App/AppIcon.vue';
import AppLink from '~/components/partials/App/AppLink.vue';
import AppLoader from '~/components/partials/App/AppLoader.vue';

export type BorderColors = 'none' | 'black' | 'red' | 'grey' | 'transparent';
export type BgColors = 'white' | 'black' | 'red' | 'grey' | 'lightGrey' | 'transparent';
export type TextColors = 'white' | 'black' | 'red';
type WeightTypes = 'normal' | 'bold';

export type SizeTypes =
  | 'h-8'
  | 'h-9'
  | 'h-10'
  | 'h-11'
  | 'w-164px'
  | 'w-180px'
  | 'w-220px'
  | 'w-260px'
  | 'w-274px'
  | 'w-315px';

export default defineComponent({
  components: {
    AppIcon,
    AppLink,
    AppLoader,
  },
  props: {
    border: {
      type: String as PropType<BorderColors>,
      default: 'black',
    },
    bg: {
      type: String as PropType<BgColors>,
      default: 'white',
    },
    text: {
      type: String as PropType<TextColors>,
      default: 'black',
    },
    weight: {
      type: String as PropType<WeightTypes>,
      default: 'normal',
    },
    size: {
      type: String as PropType<SizeTypes>,
      default: 'h-8',
    },
    value: {
      type: String,
      default: null,
    },
    iconName: {
      type: String,
      default: '',
    },
    to: {
      type: [Object, String] as PropType<RawLocation>,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noHover: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String as PropType<'a' | 'NuxtLink'>,
      default: null,
    },
  },
  setup(props, { slots }) {
    // NOTE: SSR時$slotsが空になってしまうのでcomputedで実装
    const showIcon = computed(() => props.iconName || slots.icon);

    const isDisabled = computed(() => props.disabled || props.loading);

    return {
      showIcon,
      isDisabled,
    };
  },
});
