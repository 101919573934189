// 注文履歴の表示開始年
export const FIRST_ORDER_HISTORY_YEAR = 2009;

// 注文履歴の表示数
export const DISPLAY_ORDER_NUM = 30;

// 注文状態
export const ORDER_STATUS = {
  complete: '発送完了',
  changeable: '変更受付中',
  arranging: '手配中',
  arranged: '手配済み',
  cancel: 'キャンセル',
  back_order: '入荷待ち',
  waiting_delivery: '引き渡し可',
  payment_failed: '注文不成立',
  authorizing: '与信取得中',
};

// クレジットカード一括払い
export const ONE_TIME_PAYMENT = 0;

// クレジットカードボーナス払い
export const BONUS_PAYMENT = 51;

// 配送会社ヤマト
export const YAMATO_SHIPMENT_COMPANY = 'ヤマト';

// ボジョレー判別
export const BEAUJOLAIS = 'presale_beaujolais';
