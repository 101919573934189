






import { computed, defineComponent, PropType, useRouter, useRoute } from '@nuxtjs/composition-api';
import { RawLocation } from 'vue-router';
import { useEnotecaApp } from '~/composables/useEnotecaApp';
import { useRuntimeConfig } from '~/composables/useRuntimeConfig';
import { stringifyLocation, url } from '~/utils';

export default defineComponent({
  inheritAttrs: false,
  props: {
    /** タグ名 */
    tag: {
      type: String as PropType<'a' | 'NuxtLink'>,
      default: null,
    },
    /** 遷移先 */
    to: {
      type: [Object, String] as PropType<RawLocation>,
      required: true,
    },
    /** target */
    target: {
      type: String,
      default: null,
    },
  },
  setup(props, { attrs }) {
    const { FRONTEND_ORIGIN } = useRuntimeConfig();
    const router = useRouter();
    const route = useRoute();
    const { isEnotecaAppWebView } = useEnotecaApp();

    // 新しいタブで開くか
    const isNewTab = computed(() => props.target === '_blank');
    // タグの名前
    const tagName = computed((): 'a' | 'NuxtLink' => {
      // アプリのネイティブ画面からWebViewを起動する場合、通常のaタグにする必要がある
      const { path } = route.value;
      const isAppNativePage = path === url('EVENT_LIST') || path === url('TOP') || path === url('ARTICLE_LIST');
      if (isAppNativePage && isEnotecaAppWebView.value) {
        return 'a';
      }
      if (props.tag) {
        return props.tag;
      }

      const href = stringifyLocation(props.to, FRONTEND_ORIGIN);
      const isRoute = router.getMatchedComponents(href);

      return isNewTab.value || isRoute.length === 0 ? 'a' : 'NuxtLink';
    });
    // 遷移先
    const href = computed(() => stringifyLocation(props.to, FRONTEND_ORIGIN));
    // ルート要素へ付与する属性
    const rootAttrs = computed(() => {
      const rootAttrs: Record<string, any> = {
        ...attrs,
      };

      if (isNewTab.value && !isEnotecaAppWebView.value) {
        rootAttrs.target = '_blank';
        rootAttrs.rel = 'noopener noreferrer';
      }

      if (tagName.value === 'NuxtLink') {
        rootAttrs.to = href.value;
      } else {
        rootAttrs.href = href.value;
      }

      return rootAttrs;
    });

    return {
      tagName,
      rootAttrs,
    };
  },
});
