import { RawLocation } from 'vue-router';
import { stringifyLocation } from '~/utils';
import { LoginStatus } from '~/enums/login';

export type GaTopPartsType =
  | 'スライダー枠'
  | '毎月更新'
  | 'KARTE_contents'
  | 'KARTE_popup'
  | 'おすすめ'
  | 'ニュース'
  | 'バナー枠'
  | '新着生産者'
  | 'グルメなおつまみ'
  | 'ハイエンド'
  | '頒布会・定期便'
  | 'シーン'
  | 'キーワード'
  | '単品枠(いま注目のワイン)'
  | '単品枠(おすすめワイン)'
  | '単品枠(ワインセット)'
  | '産地特集(ボルドー)'
  | '産地特集(ブルゴーニュ)'
  | '産地特集(イタリア)'
  | '産地特集(シャンパーニュ)'
  | '産地特集(カリフォルニア)'
  | '産地特集(日本)'
  | '産地特集(その他国・地域)'
  | '産地特集(その他フランス)'
  | 'ワインの読み物'
  | '新着スタッフレビュー'
  | 'ニュース（お知らせ・店舗情報）'
  | '丸アイコン'
  | '商品詳細レコメンド'
  | '定期お届け便・先行販売'; // TODO:頒布会のLPがFIXしたら削除

/**
 * Google Analyticsにイベント送信
 * @param partsType パーツ種類
 * @param url リンク先
 * @param title タイトル
 */
export const sendGaEvent = (partsType: GaTopPartsType, url?: RawLocation | null, title?: string) => {
  if (!url) return;

  if (typeof window.ga === 'function') {
    window.ga('send', 'event', partsType, stringifyLocation(url, location.origin), title, 1, { nonInteraction: true });
  }

  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'tlb.top-parts',
      event_action: partsType,
      event_category: stringifyLocation(url, location.origin),
      event_label: title,
      value: 1,
    });
  }
};

export const send3DSecureEvent = (cardNo: string | undefined, errorCode: string | null) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'tlb.3DSecure',
      event_action: errorCode ? '3ds-failed' : '3ds-succeed',
      event_label: cardNo,
      value: errorCode || undefined,
    });
  }
};

export const sendOrderEvent = (type: string) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'tlb.go_to_order',
      event_action: 'go_to_order',
      event_label: type,
    });
  }
};

export const sendCartEvent = (type: string, variantCodeList: string[], url: string) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'tlb.add_cart',
      event_action: 'add_cart',
      event_label: type,
      item_list: variantCodeList,
      event_category: url,
    });
  }
};

export const sendSeeMoreButtonOnProductList = (count: number, params: any) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'tlb.see_more_button_counter',
      event_action: 'see_more_button_counter',
      event_counter: count,
      item_list_params: params,
    });
  }
};

export const sendLoginEvent = (label: LoginStatus, category: string) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'tlb.login_event',
      event_action: 'login_event',
      event_category: category,
      event_label: label,
    });
  }
};

export const gaEventType = {
  enotecaOnlineLogo: {
    pc: {
      event: 'tlb.GN_PC_ENOTECAonlineLogo',
      eventAction: 'GN_PC_ENOTECAonlineロゴ',
    },
    sp: {
      event: 'tlb.GN_SP_ENOTECAonlineLogo',
      eventAction: 'GN_SP_ENOTECAonlineロゴ',
    },
  },
  languages: {
    pc: {
      event: 'tlb.GN_PC_LANGUAGES',
      eventAction: 'GN_PC_Languages',
    },
    sp: undefined,
  },
  guide: {
    pc: {
      event: 'tlb.GN_PC_GUIDE',
      eventAction: 'GN_PC_GUIDE',
    },
    sp: {
      event: 'tlb.GN_SP_GUIDE',
      eventAction: 'GN_SP_GUIDE',
    },
  },
  mypage: {
    pc: {
      event: 'tlb.GN_PC_MYPAGE',
      eventAction: 'GN_PC_マイページ',
    },
    sp: {
      event: 'tlb.GN_SP_MYPAGE',
      eventAction: 'GN_SP_マイページ',
    },
  },
  mywine: {
    pc: {
      event: 'tlb.GN_PC_MYWINE',
      eventAction: 'GN_PC_マイワイン',
    },
    sp: {
      event: 'tlb.GN_SP_MYWINE',
      eventAction: 'GN_SP_マイワイン',
    },
  },
  cart: {
    pc: {
      event: 'tlb.GN_PC_CART',
      eventAction: 'GN_PC_カート',
    },
    sp: {
      event: 'tlb.GN_SP_CART',
      eventAction: 'GN_SP_カート',
    },
  },
  searchItem: {
    pc: {
      event: 'tlb.GN_PC_SEARCH_ITEM',
      eventAction: 'GN_PC_商品を探す',
    },
    sp: undefined,
  },
  ranking: {
    pc: {
      event: 'tlb.GN_PC_RANKING',
      eventAction: 'GN_PC_ランキング',
    },
    sp: undefined,
  },
  wineSet: {
    pc: {
      event: 'tlb.GN_PC_ WINE_SET',
      eventAction: 'GN_PC_ワインセット',
    },
    sp: {
      event: 'tlb.GN_SP_ WINE_SET',
      eventAction: 'GN_SP_セット',
    },
  },
  wineGift: {
    pc: {
      event: 'tlb.GN_PC_WINE_GIFT',
      eventAction: 'GN_PC_ワインギフト',
    },
    sp: {
      event: 'tlb.GN_SP_WINE_GIFT',
      eventAction: 'GN_SP_ワインギフト',
    },
  },
  specialFeature: {
    pc: {
      event: 'tlb.GN_PC_SPECIAL_FEATURE',
      eventAction: 'GN_PC_特集',
    },
    sp: {
      event: 'tlb.GN_SP_SPECIAL_FEATURE',
      eventAction: 'GN_SP_特集',
    },
  },
  staffReview: {
    pc: {
      event: 'tlb.GN_PC_STAFF_REVIEW',
      eventAction: 'GN_PC_スタッフレビュー',
    },
    sp: undefined,
  },
  magazine: {
    pc: {
      event: 'tlb.GN_PC_MAGAGINE',
      eventAction: 'GN_PC_ワインの読み物',
    },
    sp: {
      event: 'tlb.GN_SP_MAGAGINE',
      eventAction: 'GN_ SP_ワインの読み物',
    },
  },
  shop: {
    pc: {
      event: 'tlb.GN_PC_SHOP',
      eventAction: 'GN_PC_SHOP',
    },
    sp: undefined,
  },
  event: {
    pc: {
      event: 'tlb.GN_PC_EVENT',
      eventAction: 'GN_PC_EVENT',
    },
    sp: {
      event: 'tlb.GN_SP_EVENT',
      eventAction: 'GN_SP_EVENT',
    },
  },
  search: {
    pc: {
      event: 'tlb.GN_PC_SEARCH',
      eventAction: 'GN_PC_検索',
    },
    sp: {
      event: 'tlb.GN_SP_SEARCH',
      eventAction: 'GN_SP_検索',
    },
  },
  top: {
    pc: undefined,
    sp: {
      event: 'tlb.GN_SP_TOP',
      eventAction: 'GN_SP_トップ',
    },
  },
  hamburger: {
    pc: undefined,
    sp: {
      event: 'tlb.GN_SP_ HAMBURRGER_MENU',
      eventAction: 'GN_SP_ハンバーガーメニュー',
    },
  },
  searchTop: {
    pc: undefined,
    sp: {
      event: 'tlb.GN_SP_SEARCH_TOP',
      eventAction: 'GN_SP_検索TOP',
    },
  },
};

// ヘッダーのエノテカのロゴ
export const sendNavigationEvent = (
  key: keyof typeof gaEventType,
  isSmartPhone: boolean,
  eventCategory?: string,
  eventLabel?: string
) => {
  const event = isSmartPhone ? gaEventType[key].sp?.event : gaEventType[key].pc?.event;
  const eventAction = isSmartPhone ? gaEventType[key].sp?.eventAction : gaEventType[key].pc?.eventAction;

  if (window.dataLayer && event && eventAction) {
    window.dataLayer.push({
      event,
      event_action: eventAction,
      event_category: eventCategory,
      event_label: eventLabel,
    });
  }
};

export type GaArticlePartsType = 'おどるわいん_スライダー枠';

export const sendArticleEvent = (partsType: GaArticlePartsType, url?: RawLocation | null, title?: string) => {
  if (!url) return;

  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'tlb.article',
      event_action: partsType,
      event_category: stringifyLocation(url, location.origin),
      event_label: title,
    });
  }
};
