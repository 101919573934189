import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { ConsolsActionPayload, BaseAxiosAction } from '~/types/api';

export type Ctx = DefineActionContext<never, never, never>;

export const actions = {
  // MDKトークンサーバからトークンを取得
  async fetchToken(
    this: Vue,
    context: Ctx,
    payload: BaseAxiosAction<{
      card_number: string;
      card_expire: string;
      security_code?: string;
      cardholder_name?: string;
      token_api_key: string;
      lang?: 'en' | 'ja';
    }>
  ) {
    return await this.$axios.$post<{
      token?: string;
      token_expire_date?: string;
      req_card_number?: string;
      status: 'success' | 'failure';
      code: string;
      message: string;
    }>('https://api3.veritrans.co.jp/4gtoken', payload);
  },
  // 会員情報追加
  async addAccount(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/veritrans/account/add', 'post'>) {
    return await this.$consolsAxios.$post('/api/v1/veritrans/account/add', payload);
  },
  // 会員情報紐付け
  async linkAccount(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/veritrans/account/link', 'post'>) {
    return await this.$consolsAxios.$post('/api/v1/veritrans/account/link', payload);
  },
  /**
   * 許可
   * リクエストパラメータについて：
   * serviceOptionTypeは常に mpi-company になります
   * orderId は uuid
   * amount は常に '1'
   */
  async authorize(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/veritrans/mpi/authorize', 'post'>) {
    return await this.$consolsAxios.$post('/api/v1/veritrans/mpi/authorize', payload);
  },
  // 本人認証結果確認
  async authorizationResult(
    this: Vue,
    context: Ctx,
    payload: ConsolsActionPayload<'/api/v1/veritrans/mpi/result', 'post'>
  ) {
    return await this.$consolsAxios.$post('/api/v1/veritrans/mpi/result', payload);
  },
  async fetchUUID(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/getuuid', 'get'>) {
    const response = await this.$consolsAxios.$get('/api/v1/getuuid', payload);
    return response.uuid;
  },
  // クレカ更新
  async updateCreditcard(this: Vue, context: Ctx, payload: ConsolsActionPayload<'/api/v1/veritrans/card', 'put'>) {
    return await this.$consolsAxios.$put('/api/v1/veritrans/card', payload);
  },
};

export type Store = DefineStoreModule<'modules/veritrans', never, never, never, typeof actions>;
