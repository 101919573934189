import urlJoin from 'url-join';

/**
 * ページURLの関連付け
 * 命名はなるべくパス名にそろえて
 */
export const PAGE_URL = {
  TOP: '/',
  AGE_CHECK: '/age_check',

  // 認証
  AUTH_LOGIN: '/auth/login',
  AUTH_LOGOUT: '/auth/logout',
  AUTH_ERROR: '/auth/error',
  // IDP Auth
  AUTH_CB_SUCCESS: '/auth/cb/success',
  AUTH_CB_ENOTECA_APP: '/auth/cb/enoteca-app',
  SIGN_UP: '/signup',
  SIGN_UP_COMPLETE: '/signup/complete',

  // アカウント
  PASSWORD_CONFIRM: '/password-forget/confirm',
  PASSWORD_CONFIRM_COMPLETE: '/password-forget/confirm/complete',
  PASSWORD_UPDATE: '/password-forget/update',
  PASSWORD_UPDATE_COMPLETE: '/password-forget/update/complete',

  // マイページ
  MYPAGE: '/mypage',
  MYPAGE_POINT_LIST: '/mypage/point/list',
  MYPAGE_POINT_NEW: '/mypage/point/detail/new',
  MYPAGE_POINT_CONFIRM: '/mypage/point/detail/new/confirm',
  MYPAGE_POINT_COMPLETE: '/mypage/point/detail/new/complete',
  MYPAGE_HISTORY_LIST: '/mypage/history/list',
  MYPAGE_HISTORY_DETAIL: '/mypage/history/detail/_id',
  MYPAGE_HISTORY_POS_DETAIL: '/mypage/history/shop/detail/_id',
  MYPAGE_SUBSCRIPTION_LIST: '/mypage/subscription/list',
  MYPAGE_SUBSCRIPTION_DETAIL_DATE: '/mypage/subscription/detail/_id/date',
  MYPAGE_SUBSCRIPTION_DETAIL_PAYMENT: '/mypage/subscription/detail/_id/payment',
  MYPAGE_PROFILE: '/mypage/profile',
  MYPAGE_ACCOUNT: '/mypage/account',
  MYPAGE_PASSWORD: '/mypage/password',
  MYPAGE_PASSWORD_COMPLETE: '/mypage/password/complete',
  MYPAGE_CREDIT: '/mypage/credit',
  MYPAGE_CREDIT_CARD_LIST: '/mypage/credit-card/list',
  MYPAGE_CREDIT_CARD_NEW: '/mypage/credit-card/detail/new',
  MYPAGE_CREDIT_CARD_EDIT: '/mypage/credit-card/detail/_id',
  MYPAGE_CREDIT_CARD_COMPLETE: '/mypage/credit-card/complete',
  MYPAGE_DELIVERY_LIST: '/mypage/delivery/list',
  MYPAGE_DELIVERY_EDIT: '/mypage/delivery/detail/edit/_deliveryName',
  MYPAGE_DELIVERY_NEW: '/mypage/delivery/detail/new',
  MYPAGE_DELIVERY_NEW_COMPLETE: '/mypage/delivery/detail/new/complete',
  MYPAGE_MYSHOP: '/mypage/myshop',
  MYPAGE_MAGAZINE: '/mypage/magazine',
  MYPAGE_FAVORITE_LIST: '/mypage/favorite/list',
  MYPAGE_COUPON_LIST: '/mypage/coupon/list',
  MYPAGE_CELLAR: '/mypage/cellar',
  MYPAGE_CELLAR_LIST: '/mypage/cellar/list',
  MYPAGE_CELLAR_MIX_CELLAR_DETAIL: '/mypage/cellar/mix_cellar/_id',
  MYPAGE_CELLAR_ONLY_CELLAR_DETAIL: '/mypage/cellar/only_cellar/_id',
  MYPAGE_CELLAR_OUT_LIST: '/mypage/cellar/out/list',
  MYPAGE_CELLAR_OUT_FORM: '/mypage/cellar/out/form',
  MYPAGE_CELLAR_OUT_DETAIL: '/mypage/cellar/out/detail/_id',
  MYPAGE_CELLAR_BILLING_LIST: '/mypage/cellar/billing/list',
  MYPAGE_CELLAR_BILLING_DETAIl: '/mypage/cellar/billing/detail/_id',
  MYPAGE_COMMENT_LIST: '/mypage/comment/list',
  MYPAGE_MIX_CELLAR_FORM: '/mypage/cellar/mix_cellar/form',
  MYPAGE_MIX_CELLAR_DELIVERY: '/mypage/cellar/mix_cellar/delivery',
  MYPAGE_MIX_CELLAR_CONFIRM: '/mypage/cellar/mix_cellar/confirm',
  MYPAGE_ONLY_CELLAR_FORM: '/mypage/cellar/only_cellar/form',
  MYPAGE_ONLY_CELLAR_DELIVERY: '/mypage/cellar/only_cellar/delivery',
  MYPAGE_ONLY_CELLAR_CONFIRM: '/mypage/cellar/only_cellar/confirm',
  MYPAGE_CELLAR_COMPLETE: '/mypage/cellar/out/complete',
  MYPAGE_CELLAR_GIFT_WRAPPING: '/mypage/cellar/only_cellar/gift-wrapping',
  MYPAGE_CELLAR_PAPER_BAG: '/mypage/cellar/only_cellar/paper-bag',
  MYPAGE_CELLAR_SENDER: '/mypage/cellar/sender',
  MYPAGE_CELLAR_SENDER_NEW: '/mypage/cellar/sender/new',
  MYPAGE_DM: '/mypage/dm',
  MYPAGE_LOTTERY_HISTORY: '/mypage/lottery/list',
  MYPAGE_LOTTERY_DETAIL: '/mypage/lottery/detail/_id',

  // カート
  CART: '/cart',

  // 注文
  ORDER: '/order',
  ORDER_SENDER: '/order/sender',
  ORDER_RECIPIENT: '/order/recipient',
  ORDER_DELIVERY_DATE: '/order/delivery-date',
  ORDER_PAYMENT_METHOD: '/order/payment-method',
  ORDER_RECEIPT_ATTACHMENT: '/order/receipt-attachment',
  ORDER_GIFT_WRAPPING: '/order/gift-wrapping',
  ORDER_PAPER_BAG: '/order/paper-bag',
  ORDER_NOTE: '/order/note',
  ORDER_REMARKS: '/order/remarks',
  ORDER_CELLAR_INFO: '/order/cellar-info',
  ORDER_PRIMEUR_POLICY: '/order/primeur-policy',
  ORDER_DISTRIBUTION_POLICY: '/order/distribution-policy',
  ORDER_SIGNUP: '/order/signup',
  ORDER_COMPLETE: '/order/complete',
  ORDER_CALLBACK_AMAZON: '/order/cb/amazon',
  ORDER_CALLBACK_RAKUTEN_PAY: '/order/cb/rakuten-pay',
  ORDER_LEAFLET: '/order/leaflet',

  // 商品一覧
  ITEM_LIST: '/item/list',
  ITEM_DETAIL: '/item/detail/_code',

  // 主要検索(sp)
  ITEM_SEARCH: '/item/search',

  // 頒布会
  ITEM_DISTRIBUTION_DETAIL: '/item/detail/distribution/_code',

  // プリムール
  PRIMEUR: '/primeur',
  PRIMEUR_LIST: '/primeur/list',
  PRIMEUR_DETAIL: '/primeur/detail/_code',
  PRIMEUR_POLICY: '/primeur/policy',
  PRIMEUR_TERMS: '/primeur/terms',
  PRIMEUR_PROMPT: '/primeur/loginSection',
  PRIMEUR_2022_POLICY: 'https://assets.store.enoteca.co.jp/feature/pdf/2022primeur_kiyaku.pdf',
  PRIMEUR_2021_POLICY: 'https://assets.store.enoteca.co.jp/feature/pdf/2021primeur_kiyaku.pdf',

  // プリムール引き渡し
  MYPAGE_PRIMEUR_DELIVERY_TYPE: '/mypage/primeur/_orderId/delivery/type',
  MYPAGE_PRIMEUR_DELIVERY_DATE: '/mypage/primeur/_orderId/delivery/date',
  MYPAGE_PRIMEUR_DELIVERY_ADDRESS: '/mypage/primeur/_orderId/delivery/address',
  MYPAGE_PRIMEUR_DELIVERY_CELLAR_TERMS: '/mypage/primeur/_orderId/delivery/cellar/terms',
  MYPAGE_PRIMEUR_DELIVERY_CELLAR_CREDIT: '/mypage/primeur/_orderId/delivery/cellar/credit',
  MYPAGE_PRIMEUR_DELIVERY_CONFIRM: '/mypage/primeur/_orderId/delivery/confirm',
  MYPAGE_PRIMEUR_DELIVERY_COMPLETE: '/mypage/primeur/_orderId/delivery/complete',

  // イベント予約管理
  MYPAGE_EVENT_LIST: '/mypage/event/list',
  MYPAGE_EVENT_DETAIL: '/mypage/event/detail/_reservationId',
  MYPAGE_EVENT_WAITING_DETAIL: '/mypage/event/detail/waiting/_reservationId',

  // イベント予約変更
  MYPAGE_EVENT_RESERVE_CHANGE_DATE: '/mypage/event/reserve/_reservationId/date',
  MYPAGE_EVENT_RESERVE_CHANGE_FORM: '/mypage/event/reserve/_reservationId/form',
  MYPAGE_EVENT_RESERVE_CHANGE_PAYMENT: '/mypage/event/reserve/_reservationId/payment',
  MYPAGE_EVENT_RESERVE_CHANGE_CONFIRM: '/mypage/event/reserve/_reservationId/confirm',
  MYPAGE_EVENT_RESERVE_CHANGE_COMPLETE: '/mypage/event/reserve/_reservationId/complete',

  // コンテンツ（特集）
  ARCHIVES_LIST: '/archives/list',
  ARCHIVES_DETAIL: '/archives/detail/_permalink',
  // 今月の送料無料ワイン
  ARCHIVES_FREE_SHIPPING: '/archives/detail/9H',
  // ワインの頒布会、サブスクリプション
  ARCHIVES_DISTRIBUTION: '/archives/detail/HF',
  // ボジョレー・ヌーヴォー
  ARCHIVES_NOUVEAU: '/archives/detail/BJ',
  // レンタルセラーキャンペーン
  ARCHIVES_CELLAR_CAMPAIGN: '/archives/detail/Rental-cellerin-campaign',

  // 読み物
  ARTICLE_LIST: '/article/',
  ARTICLE_LIST_CATEGORY: '/article/archives/category/_permalink/',
  ARTICLE_LIST_AUTHOR: '/article/archives/author/_permalink/',
  ARTICLE_DETAIL: '/article/archives/_permalink/',
  NEWS_DETAIL: '/news/detail/_permalink',
  ARTICLE_IMAGE_PATH: 'https://assets.store.enoteca.co.jp/feature/odoru_wine/images/main_PC.jpg',

  // 生産者
  PRODUCER_LIST: '/producer/list',
  PRODUCER_DETAIL: '/producer/detail/_id',

  // クチコミ
  COMMENT_LIST: '/comment/list',
  COMMENT_FORM_NEW: '/item/detail/_code/comment/new',
  COMMENT_FORM_CONFIRM: '/item/detail/_code/comment/new/confirm',
  COMMENT_FORM_COMPLETE: '/item/detail/_code/comment/new/complete',
  USER_COMMENTS: '/comment/list/user/_commentId',

  // スタッフレビュー
  STAFF_REVIEW_LIST: '/staff-review/list',
  STAFF_REVIEW_LIST_PRODUCT: '/staff-review/list/product/_productCode',
  STAFF_REVIEW_DETAIL: '/staff-review/detail/_id',
  STAFF_LIST: '/staff/list',
  STAFF_DETAIL: '/staff/detail/_id',

  // ブログ
  BLOG_LIST: '/shop/blog',
  BLOG_TOP: '/shop/blog/_permalink',
  BLOG_DETAIL: '/shop/blog/_permalink/detail/_articleId',

  // ワインノート
  MYWINE: '/my-wine',
  PURCHASE_HISTORY: '/my-wine/purchase-history/list',
  WINENOTE: '/my-wine/notes',
  PERSONAL_REPORT: '/my-wine/personalReports/show',
  WINE_DIAGNOSIS: '/my-wine/wine-diagnosis',
  WINENOTE_PURCHASE_HISTORY: '/purchase-history/list',
  WINENOTE_NOTE: '/notes',
  WINENOTE_PERSONAL_REPORT: '/personalReports/show',
  WINENOTE_WINE_DIAGNOSIS: '/wine-diagnosis',
  WINE_DIAGNOSIS_TODAY_MOOD: '/today-mood/top',
  WINE_DIAGNOSIS_YOUR_WINE_TYPES: '/your-wine-types/top',
  WINE_DIAGNOSIS_TODAY_MOOD_TOP: '/my-wine/wine-diagnosis?p=/today-mood/top',
  WINE_DIAGNOSIS_YOUR_WINE_TYPES_TOP: '/my-wine/wine-diagnosis?p=/your-wine-types/top',
  DIAGNOSIS_WINE_TYPES: '/my-wine/wine-diagnosis/your-wine-types',
  DIAGNOSIS_TODAY_MOOD: '/my-wine/wine-diagnosis/today-mood',
  FAVORITE_LIST: '/my-wine/favorite/list',

  // 料理から探す
  FOOD_DIAGNOSIS: '/food-diagnosis/top',
  FOOD_SELECTION: '/food-diagnosis/step1',
  WINE_SELECTION: '/food-diagnosis/step2',
  PRICE_SELECTION: '/food-diagnosis/step3',
  FOOD_DIAGNOSIS_RESULT: '/food-diagnosis/result',

  // 品種・生産地一覧
  WINE_GRAPE: '/wine/grape',
  WINE_AREA: '/wine/area',

  // イベント
  EVENT_LIST: '/shop/event/all',
  EVENT_DETAIL: '/shop/event/_id',
  EVENT_WORDPRESS: '/shop/event/event-all',

  // イベント予約
  EVENT_RESERVE_DATE: '/shop/event/reserve/_id/date',
  EVENT_RESERVE_FORM: '/shop/event/reserve/_id/form',
  EVENT_RESERVE_PAYMENT: '/shop/event/reserve/_id/payment',
  EVENT_RESERVE_CONFIRM: '/shop/event/reserve/_id/confirm',
  EVENT_RESERVE_COMPLETE: '/shop/event/reserve/_id/complete',
  // キャンセル待ち予約
  EVENT_WAITING_FORM: '/shop/event/waiting/_id/form',
  EVENT_WAITING_COMPLETE: '/shop/event/waiting/_id/complete',

  // 店舗
  SHOP: '/shop/',
  SHOP_DETAIL: '/shop/_permalink',
  SHOP_LIST: '/shop/all',

  // タグ検索
  TAG_SEARCH_LIST: '/contents/list',

  // レンタルセラー
  CELLAR: '/cellar',
  CELLAR_TEMPERATURE: '/cellar/temperature',
  CELLAR_MIX_CELLAR: '/cellar/mix_cellar',
  CELLAR_ONLY_CELLAR: '/cellar/only_cellar',
  CELLAR_FAQ: '/cellar/faq',
  CELLAR_FORM: '/cellar/form',
  CELLAR_POLICY: '/cellar/form/policy',
  CELLAR_CREDIT: '/cellar/form/credit',
  CELLAR_COMPLETE: '/cellar/form/complete',

  // お知らせ
  NEWS_LIST: '/news/list',
  NEWS_DETAIL_3DSecure: '/news/detail/topics_creditcard_3Dsecure',
  // リジェ・ベレール商品のご購入について
  NEWS_DETAIL_CAUTION_COMTELIGERBELAIR: '/news/detail/Caution_COMTELIGERBELAIR',
  // イベントのキャンセルポリシー
  NEWS_DETAIL_EVENT_CANCELLATION_POLICY: '/news/detail/Event_Cancellation_Policy',
  // クラブエノテカポイントサービス 及び VISA特典対象ショップ一覧
  NEWS_DETAIL_CLUB_POINT_VISA: '/news/detail/club_point_visa',

  // ギフトサービスについて
  GIFT_WRAPPING: '/gift_wrapping',

  // Karteガイド
  KARTE_GUIDE: 'https://guide.store.enoteca.co.jp/',
  KARTE_GUIDE_CALENDAR: 'https://guide.store.enoteca.co.jp/faqs/63d221d0bcc4ff0012cc72dc/',
  KARTE_GUIDE_ECON: 'https://guide.store.enoteca.co.jp/faqs/63d221d0bcc4ff0012cc72e6/',
  KARTE_GUIDE_SECURITY_CODE: 'https://guide.store.enoteca.co.jp/faqs/63d221d1bcc4ff0012cc72e8/',
  KARTE_GUIDE_CONVENIENCE_STORE: 'https://guide.store.enoteca.co.jp/faqs/63d221d0bcc4ff0012cc72e4/',
  KARTE_GUIDE_CREDIT_CARD: 'https://guide.store.enoteca.co.jp/faqs/63d221d1bcc4ff0012cc72ec/',
  KARTE_GUIDE_CASH_ON_DELIVERY: 'https://guide.store.enoteca.co.jp/faqs/63d221d1bcc4ff0012cc72eb/',
  KARTE_GUIDE_RAKUTEN_PAY: 'https://guide.store.enoteca.co.jp/faqs/63d221d1bcc4ff0012cc72ea/',
  KARTE_GUIDE_AMAZON_PAY: 'https://guide.store.enoteca.co.jp/faqs/63d221d0bcc4ff0012cc72e5/',
  KARTE_GUIDE_ECON_PAY_BANK_TRANSFER: 'https://guide.store.enoteca.co.jp/faqs/63d221d1bcc4ff0012cc72e7/',
  KARTE_GUIDE_GIFT_PAYMENT: 'https://guide.store.enoteca.co.jp/faqs/63feb5909d8daf001224cb84/',
  KARTE_GUIDE_PAIDY: 'https://guide.store.enoteca.co.jp/faqs/641822ed329197001275f36e/',
  KARTE_GUIDE_RETURN_EXCHANGE: 'https://guide.store.enoteca.co.jp/faqs/63d221d0bcc4ff0012cc72e2/',
  KARTE_GUIDE_ABOUT_PAYMENT: 'https://guide.store.enoteca.co.jp/categories/63d0cdf8bfd691001209e0dd/',
  KARTE_GUIDE_ABOUT_SHIPPING: 'https://guide.store.enoteca.co.jp/categories/63d0cdf8bfd691001209e0d8/',
  KARTE_GUIDE_ORDER_UNCERTAIN: 'https://guide.store.enoteca.co.jp/faqs/63edcd9e318f2300136a60e3/',

  // エノテカオンラインについて
  ABOUT: '/about',

  // ランキング
  RANKING: '/ranking',
  RANKING_RED: '/ranking/red',
  RANKING_WHITE: '/ranking/white',
  RANKING_SPARKLING: '/ranking/sparkling',
  RANKING_WINESET: '/ranking/wineset',

  // おすすめワイン（シルバーエッグのレコメンド）
  RECOMMEND: '/recommend',
  // 最近チェックしたアイテム（シルバーエッグの閲覧履歴）
  HISTORY: '/history',

  // メールマガジン登録/解除
  MAGAZINE: '/magazine',

  // クラブエノテカについて
  CLUB: '/club',
  CLUB_SHOP: '/club#shop',
  CLUB_SERVICE: '/club#service',
  CLUB_VISA: '/club#visa-card',

  // ワインギフト
  MYGIFT: '/mygift',
  MYGIFT_DELIVERIES: '/mygift/deliveries',
  MYGIFT_PRODUCTS: '/mygift/products',

  // 特定商取引法及び酒税法に基づく表示
  TOKUSHOHO: '/tokushoho',

  // プライバシー・ポリシー
  PRIVACY_POLICY: 'https://www.asahigroup-holdings.com/privacy_policy/',
  PRIVACY_POLICY_02: 'https://www.asahigroup-holdings.com/privacy_policy/02/index.html',

  // ご利用規約
  POLICY: '/policy',

  // コンテンツご利用規約
  CONTENT_POLICY: '/content_policy',

  // ヴィンテージライブラリー
  VINTAGE: '/vintage',

  // ヴィンテージチャート
  VINTAGE_CHART: '/vintage_chart',

  // お問い合わせ
  CONTACT_FORM: '/contact',
  CONTACT_COMPLETE: '/contact/complete',

  // LINE詳細
  FEATURE_LINE: '/feature/line',

  // アプリ詳細
  FEATURE_APP: '/feature/app',

  // 診断で探す
  FEATURE_DIAGNOSIS: '/feature/app_wineshindan',

  // おどるわいんとは
  ODORU_WINE: '/feature/odoru_wine',

  // 会社概要
  COMPANY_INFO: 'https://www.enoteca.jp',

  // 中途スタッフ募集中
  RECRUIT: 'https://recruit.enoteca.jp',

  // ヤマト配送確認
  YAMATO_SHIPMENT: 'http://toi.kuronekoyamato.co.jp/cgi-bin/tneko',

  // 佐川配送確認
  SAGAWA_SHIPMENT: 'https://k2k.sagawa-exp.co.jp/p/sagawa/web/okurijoinput.jsp',

  // DECConnect / SocialPlus LINE 連携
  DCON_SOCIAL_LOGIN: '/dcon/social/login',
  DCON_SOCIAL_MERGE: '/dcon/social/merge',

  // AmazonPayログイン
  LOGIN_WITH_AMAZON: '/login-with-amazon',

  // Appleログイン
  LOGIN_WITH_SOCIAL: '/login-with-social',

  // 楽天ペイ
  RAKUTEN_PAY: 'https://checkout.rakuten.co.jp/',

  // 楽天ペイ（旧　楽天ID決済）
  OLD_RAKUTEN_PAY: 'https://checkout.rakuten.co.jp/shopping/',

  // VISA認証サービス
  VISA_CERTIFICATION_SERVICE: 'https://www.visa.co.jp/pay-with-visa/security/secure-online-shopping.html',

  // J/SecureTM（ジェイセキュア）
  J_SECURE_TM: 'https://www.jcb.jp/security/jsecure/index.html',

  // ProtectBuy
  PROTECT_BUY: 'https://www.diners.co.jp/ja/usage/3d_secure.html',

  // コンビニ後払い
  SCORE_ATOBARAI_SERVICE: 'https://www.scoring.jp/service/atobarai/',

  // Windows更新方法
  WINDOW_UPDATE: 'https://www.microsoft.com/ja-jp/safety/protect/musteps.aspx',

  // Mac更新方法
  MAC_UPDATE: 'https://support.apple.com/ja-jp/guide/mac-help/mchlpx1065/mac',

  // 永久不滅
  AQF_COM: 'https://www.a-q-f.com/saison/wallet/list/index.html',

  // ギフトカードについて
  ABOUT_GIFTCARD: '/feature/giftcard/',

  // チャージ金額残高紹介
  GIFT_CHARGE_BALANCE: 'https://www.vcsys.com/s/enoteca/p/%3bjsessionid=F7F60344C69B958027CD84BA7C0E150E',

  // Veritrans
  VERITRANS_CALLBACK_ORDER: '/veritrans/callback/order',

  // スコア払いバナーリンク
  SCORING_BANNER: 'https://www.scoring.jp/consumer/',

  // シーンから探す
  SCENE_LIST: '/scene/list',

  // キーワードから探す
  KEYWORD_LIST: '/keyword/list',

  // ワインカレッジ
  COLLEGE_CATEGORY_LIST: '/wine-college/lesson/category/list', // 講座カテゴリ一覧
  COLLEGE_BASIC_LIST: '/wine-college/basic/lesson/list', // ワイン基礎講座一覧
  COLLEGE_BASIC_DETAIL: '/wine-college/basic/lesson/_id', // ワイン基礎講座詳細
  COLLEGE_BASIC_ARTICLE: '/wine-college/basic/lesson/_id/page/_page', // ワイン基礎講座読み物詳細
  COLLEGE_BASIC_QUIZ: '/wine-college/basic/quiz/_id/page/_page', // ワイン基礎講座クイズ
  COLLEGE_BASIC_RESULT: '/wine-college/basic/quiz/_id/result/_page', // ワイン基礎講座クイズの答え
  COLLEGE_PRODUCING_AREA_LIST: '/wine-college/producing-area/lesson/list', // ワイン産地講座一覧
  COLLEGE_PRODUCING_AREA_DETAIL: '/wine-college/producing-area/lesson/_id', // ワイン産地講座詳細
  COLLEGE_PRODUCING_AREA_ARTICLE: '/wine-college/producing-area/lesson/_id/page/_page', // ワイン産地講座読み物詳細
  COLLEGE_PRODUCING_AREA_QUIZ: '/wine-college/producing-area/quiz/_id/page/_page', // ワイン産地講座クイズ
  COLLEGE_PRODUCING_AREA_RESULT: '/wine-college/producing-area/quiz/_id/result/_page', // ワイン産地講座クイズの答え
  COLLEGE_QUIZ_DAILY: '/wine-college/quiz/daily', // 今日のクイズ
  COLLEGE_QUIZ_DAILY_QUIZ: '/wine-college/quiz/daily/page', // 今日のクイズ質問画面
  COLLEGE_QUIZ_DAILY_RESULT: '/wine-college/quiz/daily/result', // 今日のクイズ結果画面
  // 抽選詳細
  LOTTERY_DETAIL: '/lottery/detail/_id',

  // アプリの外部ブラウザへのディープリンク
  APP_EXTERNAL_LINK: 'enoteca-app-webview://external_browser?url=',
} as const;

// パスパラメータの取りうる値の型
type UrlParamValue = string | number | boolean | undefined;
// URLからパスパラメータを取り出す
type UrlParams<T extends string> =
  // https:// http:// から始まる場合はパスのみに分離
  T extends `${string}://${string}/${infer X}`
    ? UrlParams<`/${X}`>
    : // 冒頭にパスパラメータが含まれる場合はパスパラメータを登録して後続のパスを再帰処理
    T extends `/_${infer X}/${infer Y}`
    ? { [K in X]: UrlParamValue } & UrlParams<`/${Y}`>
    : // １階層のみでパスパラメーアの場合はパスパラメータを登録する
    T extends `/_${infer X}`
    ? { [K in X]: UrlParamValue }
    : // 冒頭にパスパラメータが含まれない場合は後続のパスを再帰処理
    T extends `/${string}/${infer X}`
    ? UrlParams<`/${X}`>
    : {};

// ページキーとページURLのマップ
type PageUrls = typeof PAGE_URL;
// パスパラメータを持つURLキーの一覧
type PageKeysWithoutPathParams = {
  [K in keyof PageUrls]: {} extends UrlParams<PageUrls[K]> ? K : never;
}[keyof PageUrls];
type PageKeysWithPathParams = {
  [K in keyof PageUrls]: {} extends UrlParams<PageUrls[K]> ? never : K;
}[keyof PageUrls];
interface GetUrl {
  // パスパラメータを持つURLの場合は第２引数を必須指定
  <K extends PageKeysWithPathParams>(key: K, params: UrlParams<PageUrls[K]>): string;
  // パスパラメータを持つURLの場合は第２引数を省略
  <K extends PageKeysWithoutPathParams>(key: K): string;
}

export const url: GetUrl = (key: keyof PageUrls, params = {}) => {
  const url = PAGE_URL[key];

  if (typeof url !== 'string') {
    return '';
  }

  return url.replace(/\/_([^/]+)/g, (matched, $1) => {
    // @ts-expect-error
    const param = params[$1] || '';

    return `/${param}`;
  });
};
/**
 * urlから、一番末尾に近いスラッシュ(/)とその後に続く文字を削除する
 * HACK:一致判定に第２引数が必要なパスの場合、末尾の'_id'等、判定に不要な文字が含まれるため
 */
export const removeEndOfPath = (path: string) => {
  const pathArr = path.split('/');
  pathArr.pop();
  return pathArr.join('/');
};

/**
 * vue-routerの型から文字列型に変換する
 */
export const convertRouteToUrl = (
  url:
    | string
    | {
        path: string;
        query?: Record<string, string | Array<string> | undefined>;
      }
): string => {
  if (typeof url === 'string') {
    return url;
  }

  const { path, query } = url;
  if (!query) {
    return path;
  }

  const queries = Object.entries(query).map(([key, value]) => {
    if (!value) return false;
    if (typeof value === 'string') {
      return `${key}=${value}`;
    }
    return value.map((v) => `${key}=${v}`).join('&');
  });

  return urlJoin(path, `?${queries.join('&')}`);
};
