var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.to ? 'AppLink' : 'button',_vm._g(_vm._b({tag:"Component",staticClass:"relative flex w-full items-center justify-center rounded font-sans transition duration-200 hover:no-underline",class:[
    // ボタンサイズ
    // h-9：PC商品一覧の絞り込みボタン
    // h-11：フッターメニューのSNSボタン
    {
      'h-8 text-xs': _vm.size === 'h-8',
      'h-9 text-xs lg:max-w-max lg:px-3 xl:w-button180px xl:max-w-none xl:px-0': _vm.size === 'h-9',
      'h-10 text-base': _vm.size === 'h-10',
      'h-10 md:w-button164px': _vm.size === 'w-164px',
      'h-10 md:w-button180px': _vm.size === 'w-180px',
      'h-10 md:w-button220px': _vm.size === 'w-220px',
      'h-10 md:w-button260px': _vm.size === 'w-260px',
      'h-10 md:w-button274px': _vm.size === 'w-274px',
      'h-10 md:w-button315px': _vm.size === 'w-315px',
      'h-11 md:w-60': _vm.size === 'h-11',
    },
    // フォント幅
    {
      'font-semibold': _vm.weight === 'bold',
    },
    // 非活性
    {
      'pointer-events-none cursor-default !border-0 !bg-Black08 !text-Black40': _vm.isDisabled,
    },
    // ボーダー
    {
      'border border-Black88': _vm.border !== _vm.bg && _vm.border === 'black',
      'border border-WineRed': _vm.border !== _vm.bg && _vm.border === 'red',
      'border border-Black10': _vm.border !== _vm.bg && _vm.border === 'grey',
    },
    // 背景色
    {
      'bg-White': !_vm.isDisabled && _vm.bg === 'white',
      'bg-Black88': !_vm.isDisabled && _vm.bg === 'black',
      'bg-WineRed': !_vm.isDisabled && _vm.bg === 'red',
      'bg-Black10': !_vm.isDisabled && _vm.bg === 'grey',
      'bg-Black06': !_vm.isDisabled && _vm.bg === 'lightGrey',
      'bg-transparent': !_vm.isDisabled && _vm.bg === 'transparent',
    },
    // テキスト色
    {
      'text-White': (_vm.border !== _vm.bg || !_vm.isDisabled) && _vm.text === 'white',
      'text-Black88': (_vm.border !== _vm.bg || !_vm.isDisabled) && _vm.text === 'black',
      'text-WineRed': (_vm.border !== _vm.bg || !_vm.isDisabled) && _vm.text === 'red',
    },
    // ホバー
    !_vm.noHover &&
      !_vm.isDisabled &&
      (_vm.bg === 'red'
        ? 'hover:bg-WineRedHover'
        : _vm.bg === 'black'
        ? 'hover:bg-Black'
        : _vm.border !== _vm.bg && _vm.border === 'red'
        ? 'hover:bg-WineRed/40'
        : _vm.border !== 'transparent' && _vm.border !== 'none' && _vm.border !== _vm.bg
        ? 'hover:bg-Black06'
        : null) ],attrs:{"to":_vm.to,"tag":_vm.to ? _vm.tag : null,"disabled":_vm.isDisabled}},'Component',_vm.$attrs,false),_vm.disabled || _vm.loading ? null : _vm.$listeners),[(_vm.loading)?_c('AppLoader',{staticClass:"mr-1 h-5 w-5",class:{ 'absolute right-0 mr-2': !_vm.showIcon },attrs:{"is-red":_vm.iconName === 'actionCart'}}):(_vm.showIcon)?_c('i',{staticClass:"mr-1 block fill-current"},[(_vm.iconName)?_c('AppIcon',{attrs:{"icon":_vm.iconName}}):_vm._t("icon")],2):_vm._e(),_vm._v(" "),_vm._t("default",[_vm._v("\n    "+_vm._s(_vm.value)+"\n  ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }